
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "Loading",
  components: {},
  setup() {
    const store = useStore();

    const isLoadingActive = computed(() => {
      return store.getters["loading/isLoading"];
    });

    return {
      isLoadingActive,
    };
  },
});
