
import { defineComponent } from "vue";
import Loading from "@/components/loading.vue";

export default defineComponent({
  name: "App",
  components: {
    Loading,
  },
  setup() {
    return {}
  },
});
